const state = () => ({
    application_version: '',
    without_user: true,
    is_send_sign_up_mail: true,
    is_bee_2_beep: false
});

const mutations = {
    with_user(state) {
        state.without_user = false;
    },
    without_user(state) {
        state.without_user = true;
    },
    setIsBee2Beep(state, is_bee_2_beep: boolean) {
        state.is_bee_2_beep = is_bee_2_beep;
    },
    setIsSendSignUpMail(state, is_send_sign_up_mail: boolean) {
        state.is_send_sign_up_mail = is_send_sign_up_mail;
    },
    setApplicationVersion(state, applicationVersion: string): void {
        state.application_version = applicationVersion
    },
};

const actions = {
    setIsSendSignUpMail({commit}, is_send_sign_up_mail: boolean) {
        commit('setIsSendSignUpMail', is_send_sign_up_mail);
    },
    with_user({commit}) {
        commit('with_user');
    },
    without_user({commit}) {
        commit('without_user');
    },
    setIsBee2Beep({commit}, is_bee_2_beep: boolean) {
        commit('setIsBee2Beep', is_bee_2_beep);
    },
    setApplicationVersion({ commit }, applicationVersion: string) {
        commit('setApplicationVersion', applicationVersion)
    },
};

const getters = {
    is_send_sign_up_mail: (state) => state.is_send_sign_up_mail,
    without_user: (state) => state.without_user,
    is_bee_2_beep: (state) => state.is_bee_2_beep,
    application_version: (state) => state.application_version
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
