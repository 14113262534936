<template>
    <div id="installation-step-one" class="div-height">
        <v-card height="100%">
            <v-card-text>
                <h1 class="text-center mt-4 title-step-1">
                    {{ $t('step_1.welcome_to_installation') }}
                </h1>

                <h3 class="text-center my-6">
                    {{ $t('step_1.wish_associate_device_to') }}
                </h3>

                <v-form ref="form">
                    <v-row class="mt-2">
                        <v-col
                            :class="value_selected === 1 ? 'value-chose' : 'value-not-chose'"
                            class="mx-5 mb-4 pointer choice-associate border-radius-choice"
                            @click="value_selected = 1"
                        >
                            <p class="text-center">
                                <v-icon v-if="value_selected === 1" color="success">mdi-check-circle</v-icon>
                            </p>
                            <p class="text-center mt-2 font-weight-bold">
                                {{ $t('step_1.for_a_client') }}
                            </p>
                            <v-text-field
                                v-model.trim="temp_email"
                                :label="$t('email')"
                                :rules="emailRules"
                                background-color="white"
                                outlined
                                @input="handleEmailChange"
                            />
                            <v-progress-linear v-if="fetching_client_by_mail" class="mt-n8" height="2" indeterminate />

                            <v-switch
                                v-if="!is_user_found"
                                v-model="is_send_sign_up_mail"
                                :label="$t('step_1.send_registration_mail')"
                                class="pl-4 mt-0"
                                inset
                            />
                        </v-col>

                        <v-col
                            v-if="!come_from_sens"
                            :class="value_selected === 2 ? 'value-chose' : 'value-not-chose'"
                            class="ml-5 mr-5 mb-4 pointer border-radius-choice"
                            @click="value_selected = 2"
                        >
                            <p v-if="value_selected === 2" class="text-center remove-margin">
                                <v-icon color="success">mdi-check-circle</v-icon>
                            </p>
                            <div class="choice-associate display-flex-content">
                                <p class="text-center ma-0">
                                    <strong class="mt-4">{{ $t('step_1.professional_account') }}</strong>
                                </p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-card-actions class="justify-end">
                        <v-btn :disabled="isDisableNextStepBtn" color="primary" large @click="prepareStepTwo()">
                            {{ $t('next') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>

        <flag-selector class="pa-4 flag-selector" />
    </div>
</template>

<script>
import FlagSelector from '@/components/global/flag-selector'
import debounce from 'lodash.debounce'

export default {
    name: 'InstallationStepOneComponent',
    components: { FlagSelector },
    data() {
        return {
            temp_email: '',
            email: '',
            emailRules: [
                (v) => !!v || this.$t('step_1.email_required'),
                (v) => /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,12}$/.test(v) || this.$t('step_1.email_not_valid'),
            ],
            fetching_client_by_mail: false,
            is_send_sign_up_mail: false,
            come_from_sens: false,
            value_selected: 1,
            is_user_found: true,
            user: {},
        }
    },
    computed: {
        isDisableNextStepBtn: function () {
            return (
                ((!/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,12}$/.test(this.email) || !/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,12}$/.test(this.temp_email)) &&
                    this.value_selected === 1) ||
                this.fetching_client_by_mail
            )
        },
    },
    methods: {
        handleEmailChange: debounce(function () {
            this.getClientByMail()
        }, 500),

        fillDataFromParamsToStep1() {
            const params = this.$store.getters['autofill/params']
            this.value_selected = parseInt(params.without_user) ? 1 : 2
            this.temp_email = params.client_email ?? ''
            this.getClientByMail()
        },

        getClientByMail() {
            if (this.$refs.form.validate()) {
                this.fetching_client_by_mail = true
                this.is_user_found = true
                this.email = ''
                this.$store.dispatch('global/with_user')
                this.axios
                    .get('/api/v1/user_client?email=' + this.temp_email)
                    .then((success) => {
                        this.is_user_found = true
                        this.is_send_sign_up_mail = false
                        this.email = this.temp_email
                        this.user = success.data.data
                        if (success.status === 206) {
                            this.showSnackbar('warning', this.$t('step_1.client_find_not_complete'))
                        } else {
                            this.showSnackbar('success', this.$t('step_1.client_find_with_mail'))
                        }
                    })
                    .catch((error) => {
                        this.manageResultIfUserClientNotFind(error)
                    })
                    .finally(() => {
                        this.fetching_client_by_mail = false
                    })
            }
        },

        manageResultIfUserClientNotFind(error) {
            if (error.response && error.response.status === 404) {
                this.is_user_found = false
                this.is_send_sign_up_mail = true
                this.email = this.temp_email
                this.showSnackbar(
                    'info',
                    this.$t('step_1.client_not_find_with_mail') + ' ' + this.$t('step_1.email_invitation_inscription_sent'),
                )
            } else if (error.response && error.response.status === 410) {
                this.manageError('warning', this.$t('step_1.email_already_taken'), error)
            } else {
                this.manageError('error', this.$t('step_1.error_during_association'), error)
            }
        },

        prepareStepTwo() {
            if (this.value_selected === 2) {
                this.$store.dispatch('global/setIsSendSignUpMail', false)
                this.$emit('goToStepTwoWithoutUser')
            } else {
                this.$store.dispatch('global/setIsSendSignUpMail', this.is_send_sign_up_mail)
                this.$emit('goToStepTwoWithUser', { user: this.user })
            }
        },
    },
    mounted() {
        this.fillDataFromParamsToStep1()
        if (this.$route.query.from_sens === null) {
            this.come_from_sens = true
            this.value_selected = 1
        }

        if (this.$route.query.from_sens === null && this.$route.query.email !== undefined) {
            this.email = this.$route.query.email
            if (/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,12}$/.test(this.email)) {
                this.getClientByMail()
            }
        }
    },
}
</script>

<style scoped>
.title-step-1 {
    line-height: 30px;
}

#installation-step-one {
    max-width: 1000px;
    margin: auto;
}

.border-radius-choice {
    border-radius: 5px;
}

.choice-associate {
    min-width: 200px;
    min-height: 150px;
}

.remove-margin {
    margin-bottom: -10px;
}

.value-not-chose {
    border: 2px #9e9e9e solid;
}

.value-chose {
    border: 2px #65b265 solid;
    background-color: #eaf6ea;
}

.display-flex-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flag-selector {
    max-width: 100px;
    margin: auto;
}
</style>
