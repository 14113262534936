import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import InstallationStep from '../views/InstallationStep.vue';
import InstallationResult from '../views/InstallationResult.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/installation',
    },
    {
        path: '/installation/:param?',
        name: 'installation',
        component: InstallationStep
    },
    {
        path: '/result',
        name: 'result',
        component: InstallationResult
    },
]

const router = new VueRouter({mode: 'history', routes: routes})

export default router
