import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import i18n from './plugins/i18n';
import global from './plugins/global.js';

Vue.config.productionTip = false;
Vue.mixin(global);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places, geocoder',
    }
});

Vue.use(VuetifyGoogleAutocomplete, {
    vueGoogleMapsCompatibility: true,
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
