<template>
    <div id="asset-domicile">
        <p>
            <strong>{{ $t('asset_domicile.complete_address_equipment') }}</strong>
        </p>

        <v-radio-group v-model="value_type_address" :rules="[(l) => !!l || $t('asset_domicile.domicile_required')]">
            <v-radio v-if="user || uid_client" :label="$t('asset_domicile.use_existing_address')" :value="1" />
            <v-radio :label="$t('asset_domicile.use_new_address')" :value="2" />
            <v-radio :label="$t('asset_domicile.locate_me')" :value="3" />
        </v-radio-group>

        <template v-if="value_type_address === 1">
            <v-select
                v-model="id_domicile"
                :items="domiciles"
                :label="$t('asset_domicile.domicile')"
                :menu-props="{ bottom: true, offsetY: true }"
                :no-data-text="$t('asset_domicile.no_domicile')"
                :rules="[(l) => !!l || $t('asset_domicile.domicile_required')]"
                item-text="name"
                item-value="id_domicile"
                outlined
            >
                <template slot="selection" slot-scope="data">
                    {{ data.item.address_name }} - {{ data.item.address }} {{ data.item.city }} {{ data.item.postal_code }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.address_name }} - {{ data.item.address }} {{ data.item.city }} {{ data.item.postal_code }}
                </template>
            </v-select>
        </template>

        <template v-if="value_type_address === 2">
            <p>
                {{ $t('selection_on_map.no_address') }}
                <a href="#" @click="showMap = !showMap">{{ $t('selection_on_map.click_on_map') }}</a>
            </p>
            <vuetify-google-autocomplete
                id="autocomplete"
                v-model="domicile_marker"
                :error-messages="error_message_domicile ? [$t('asset_domicile.error_message_domicile')] : []"
                :placeholder="$t('asset_domicile.inform_domicile_address')"
                :rules="showMap ? [] : [(l) => !!l || $t('asset_domicile.domicile_required')]"
                class="rounded-0"
                outlined
                types=""
                @placechanged="setPlace"
            />

            <selection-on-map
                :latitude="latitude"
                :longitude="longitude"
                :show-map="showMap"
                :zoom="zoom"
                v-on:getDomicileFromGeocode="getDomicileFromGeocode"
            />
        </template>

        <geolocation :value_type_address="value_type_address" v-on:getDomicileFromGeocode="getDomicileFromGeocode" />

        <v-text-field
            v-if="value_type_address === 2 || value_type_address === 3"
            v-model="domicile_name"
            :label="$t('asset_domicile.domicile_name')"
            :rules="[(v) => !!v || $t('generic_form_component.required_fields')]"
            class="rounded-0"
            outlined
        />
    </div>
</template>

<script>
import SelectionOnMap from './selection-on-map'
import Geolocation from './geolocation'

export default {
    name: 'asset-domicile',
    components: { SelectionOnMap, Geolocation },
    data() {
        return {
            error_message_domicile: false,
            value_type_address: null,
            domiciles: [],
            domicile: null,
            domicile_marker: '',
            domicile_name: '',
            id_domicile: null,
            loading_clients_domiciles: false,
            latitude: 48.864716,
            longitude: 2.349014,
            zoom: 5,
            showMap: false,
        }
    },
    props: {
        user: {
            type: Object,
            required: false,
        },
        uid_client: {
            type: String,
            required: false,
        },
    },
    watch: {
        id_domicile: function () {
            if (this.domiciles && this.id_domicile) {
                this.domicile = this.domiciles.find((d) => (d.id_domicile = this.id_domicile))
            } else {
                this.domicile = null
            }
        },
        uid_client: function () {
            this.getClientDomiciles()
            if (this.value_type_address === 1) {
                this.domicile = null
            }
        },
    },
    methods: {
        getUserClientDomiciles() {
            this.loading_clients_domiciles = true

            this.axios
                .get('/api/v1/user_client/' + this.user.id_user + '/domiciles')
                .then((success) => {
                    this.domiciles = success.data.data
                })
                .catch((error) => {
                    this.manageError('error', this.$t('asset_domicile.error_get_user_client_domiciles'), error)
                })
                .finally(() => {
                    this.loading_clients_domiciles = false
                })
        },

        getClientDomiciles() {
            this.loading_clients_domiciles = true

            this.axios
                .get(`/api/v1/client/${this.uid_client}/domiciles/public`)
                .then((success) => {
                    this.domiciles = success.data.data
                })
                .catch((error) => {
                    this.manageError('error', this.$t('asset_domicile.error_get_user_client_domiciles'), error)
                })
                .finally(() => {
                    this.loading_clients_domiciles = false
                })
        },

        /**
         *
         * @param addressData (Formatted by component address result)
         * @param place (Result given by Google)
         */
        setPlace(addressData, place) {
            this.id_domicile = null
            this.error_message_domicile = false

            //authorize user global research
            this.latitude = addressData.latitude
            this.longitude = addressData.longitude
            this.zoom = 11

            //Special case for brazil
            if (addressData.route === undefined && addressData.name) {
                addressData.route = addressData.name
            }

            if (addressData.locality === undefined && addressData) {
                addressData.locality = addressData.administrative_area_level_2
            }

            if (
                addressData.route === undefined ||
                addressData.route === '' ||
                addressData.locality === undefined ||
                addressData.locality === '' ||
                addressData.country === undefined ||
                addressData.country === '' ||
                addressData.postal_code === undefined ||
                addressData.postal_code === '' ||
                addressData.name === undefined ||
                addressData.name === ''
            ) {
                this.error_message_domicile = true
            } else {
                this.domicile = addressData

                this.domicile.address2 = !place.formatted_address.includes(place.name) ? place.name : ''

                place.address_components.forEach((component) => {
                    if (component.types.includes('country')) {
                        this.domicile.country_code = component.short_name
                    }
                })

                this.latitude = addressData.latitude
                this.longitude = addressData.longitude
                this.zoom = 13
            }
        },

        getDomicileFromGeocode(item) {
            this.domicile = item.domicile
            if (this.domicile !== null) {
                this.domicile_marker = this.domicile.name + ' ' + this.domicile.postal_code + ' ' + this.domicile.locality
            }
        },

        fillDataFromParamsToStepAddress() {
            const params = this.$store.getters['autofill/params']
            if (params.address) {
                this.domicile_marker = params.address ?? ''
                this.value_type_address = 2
            }
            this.domicile_name = params.domicile_name
        },
    },
    mounted() {
        if (this.user) {
            this.getUserClientDomiciles()
        }
        if (this.uid_client) {
            this.getClientDomiciles()
        }
        this.fillDataFromParamsToStepAddress()
    },
}
</script>
