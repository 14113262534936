<template>
    <div id="installation-result-gauge">
        <template v-if="!device_has_emitted">
            <p class="text-center">
                <strong>{{ $t('installation_result.installation_success') }}</strong>
            </p>

            <v-alert v-if="is_send_sign_up_mail" text color="success" outlined icon="mdi-check-circle">
                {{ $t('installation_result.email_inscription_sent_at') }} : <a :href="email" target="_blank">{{
                    email
                }}</a> !
                <br>
                {{ $t('installation_result.finalize_inscription') }}
            </v-alert>

            <p class="text-center ma-2">
                {{ introduction ? introduction : $t('installation_result_generic.device_activation', { device_number: device_number }) }}
            </p>

            <template v-if="total_fail_first_emission <= 5">
                <v-alert class="text-center mt-6" color="blue" dense outlined text>
                    {{ $t('installation_result.waiting_measure') }}
                </v-alert>

                <v-row align="center" class="ma-6" justify="center">
                    <v-progress-circular color="primary" indeterminate/>
                </v-row>
            </template>

            <template v-else>
                <v-alert class="text-center mt-6" color="orange" dense outlined text>
                    {{ $t('installation_result.no_measure') }}
                </v-alert>

                <v-btn block class="mb-6" color="primary" large outlined @click="getFirstDeviceEmission()">
                    {{ $t('actualize') }}
                    <v-icon>mdi-autorenew</v-icon>
                </v-btn>
            </template>

            <template v-if="installation_notice">
                <template v-if="installation_notice.includes('.mp4')">
                    <video class="margin-top-10 mx-auto" controls preload="metadata" width="600">
                        <source :src="installation_notice" type="video/mp4" />
                    </video>
                </template>
                <template v-else>
                    <v-img :src="installation_notice" alt="Image illustration" class="mx-auto" max-height="600" max-width="600"/>
                </template>
            </template>
            <p class="text-center ma-2">
                {{ installation_description }}
            </p>

            <v-btn block class="mt-8" color="primary" large @click="showCam()">
                {{ $t('installation_result.add_device_image') }}
                <v-progress-circular v-if="loading_upload_image" :size="25" :width="3" class="ml-3" color="white" indeterminate />
            </v-btn>

            <v-btn block class="mt-4 btn-line-break" large @click="navigateToDeviceDetail()">
                {{ $t('installation_result_generic.activate_device_later') }}
            </v-btn>

            <p class="text-center mt-6 mb-2">
                <router-link :to="{ path: '/installation' }" class="black--text" @click="clearInterval(timer)">
                    <strong>{{ $t('installation_result.new_installation') }}</strong>
                </router-link>
            </p>
        </template>

        <template v-else>
            <v-alert class="text-center mt-6" color="green" dense outlined text>
                <p class="text-center">
                    <strong>{{ $t('perfect') }}</strong>
                </p>
                <p>
                    {{ $t('installation_result_generic.success_get_first_emission') }}
                </p>
            </v-alert>

            <v-alert v-if="without_user" dense text color="blue" class="text-center mt-6" outlined>
                <p class="mt-4">
                    {{ $t('installation_result.reminder_to_check_emails') }}
                </p>
            </v-alert>

            <!-- @TODO: Sera modifier avec les metrics dynamiques -->
            <!-- IF GENERIC -->
            <template v-if="id_module">
                <template v-for="(metric, index) in module.default_metrics_configuration.metrics">
                    <template v-if="metric.is_visible">
                        <div :key="index" class="mb-4">
                            <p class="text-center mb-2">
                                {{ `${metric.label} ${metric.unit ? `(${metric.unit})` : ''}` }}
                            </p>

                            <h1 class="text-center">
                                {{ displayToHumanAssetMetricFromCompleteDTd(first_emission, metric.parameters) }}
                            </h1>
                        </div>
                    </template>
                </template>
            </template>

            <template v-else>
                <template v-if="!is_weight_defined">
                    <p class="text-center mb-2">
                        {{ $t('installation_result.height_liquid') }}
                    </p>

                    <h1 class="text-center">
                        <strong>{{ liquid_height }} mm</strong>
                    </h1>

                    <p class="text-center mb-2 mt-4">
                        {{ $t('installation_result.realized_measure') }}
                    </p>

                    <h1 class="text-center">
                        <strong>{{ raw_mesure }} mm</strong>
                    </h1>
                </template>

                <template v-if="is_weight_defined">
                    <p class="text-center mb-2 mt-4">{{ $t('installation_result.weight') }}</p>
                    <h1 class="text-center">{{ weight }} Kg</h1>
                </template>
            </template>

            <!-- Network quality -->
            <p class="text-center mb-2 mt-4">
                {{ $t('installation_result.network_state') }}
            </p>

            <h1 class="text-center">
                <span v-if="signal_quality === null">
                    <v-icon class="mr-1" color="disabled">mdi-network-strength-4-cog</v-icon>
                    {{ $t('installation_result.no_data') }}
                </span>
                <span v-else-if="signal_quality <= 2">
                    <v-icon class="mr-1" color="error">mdi-network-strength-1-alert</v-icon>
                    {{ $t('installation_result.bad') }}
                </span>
                <span v-else-if="signal_quality === 3">
                    <v-icon class="mr-1" color="warning">mdi-network-strength-3-alert</v-icon>
                    {{ $t('installation_result.medium') }}
                </span>
                <span v-else-if="signal_quality === 4">
                    <v-icon class="mr-1" color="green">mdi-network-strength-3</v-icon>
                    {{ $t('installation_result.good') }}
                </span>
                <span v-else-if="signal_quality === 5">
                    <v-icon class="mr-1" color="green">mdi-network-strength-4</v-icon>
                    {{ $t('installation_result.very_good') }}
                </span>
            </h1>

            <v-btn block class="mt-8" color="primary" large @click="showCam()">
                {{ $t('installation_result.add_device_image') }}
                <v-progress-circular v-if="loading_upload_image" :size="25" :width="3" class="ml-3" color="white" indeterminate />
            </v-btn>

            <v-btn block class="mt-4 btn-line-break" large @click="navigateToDeviceDetail()">
                {{ $t('installation_result_generic.see_device_detail') }}
            </v-btn>

            <p class="text-center mt-6">
                <router-link :to="{ path: '/installation' }" class="black--text">
                    <strong>{{ $t('installation_result.new_installation') }}</strong>
                </router-link>
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'InstallationResultGeneric',
    data() {
        return {
            device_has_emitted: false,
            raw_mesure: '',
            liquid_height: '',
            signal_quality: '',
            weight: '',
            timer: null,
            total_fail_first_emission: 0,
            notification_timer: 0,
            show_actualize_button: false,
            is_weight_defined: false,

            module: {},
            fetching_module: false,
            first_emission: {},
        }
    },
    props: {
        id_device: {
            type: Number,
            required: false,
        },
        device_number: {
            type: String,
            required: true,
        },
        introduction: {
            type: String,
            required: true,
        },
        installation_notice: {
            type: String,
            required: true,
        },
        installation_description: {
            type: String,
            required: true,
        },
        loading_upload_image: {
            type: Boolean,
            required: true,
        },
        email: {
            type: String,
        },
        id_module: {
            type: Number,
        },
    },
    computed: {
        is_send_sign_up_mail: function() {
            return this.$store.getters['global/is_send_sign_up_mail']
        },
        without_user: function() {
            return this.$store.getters['global/without_user']
        }
    },
    methods: {
        getFirstDeviceEmission() {
            this.axios
                .get('/api/v1/device/' + this.id_device + '/first_emission')
                .then((success) => {
                    if (success.data.data) {
                        clearInterval(this.timer)
                        this.device_has_emitted = true
                        this.show_actualize_button = false
                        this.raw_mesure = success.data.data.mesure
                        this.liquid_height = success.data.data.hauteur_liquide
                        this.signal_quality = success.data.data.signal_quality
                        this.weight = success.data.data.volume

                        this.first_emission = success.data.data

                        this.is_weight_defined = success.data.data.weight
                    } else {
                        if (!this.show_actualize_button) {
                            this.cancelTimerIfTooMuchRequest()
                        }
                        this.showSnackbar('warning', this.$t('installation_result.no_device_emission'))
                    }
                })
                .catch((error) => {
                    this.cancelTimerIfTooMuchRequest()
                    this.manageError('error', this.$t('installation_result_generic.error_get_first_emission'), error)
                })
        },

        cancelTimerIfTooMuchRequest() {
            this.total_fail_first_emission++
            if (this.total_fail_first_emission > 5) {
                clearInterval(this.timer)
                this.show_actualize_button = true
            }
        },

        navigateToDeviceDetail() {
            clearInterval(this.timer)
            this.$emit('navigateToDeviceDetail')
        },

        navigateToNewInstallation() {
            clearInterval(this.timer)
            this.$emit('navigateToDeviceDetail')
        },

        showCam() {
            this.$emit('showCam')
        },

        fetchAssetModuleById() {
            this.fetching_module = true
            this.axios
                .get(`/api/v1/module/${this.id_module}`)
                .then((success) => {
                    this.module = success.data.data
                    this.fetchAssetLiquidByIdModule()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_module = false
                })
        },
        displayToHumanAssetMetricFromCompleteDTd(data, parameter) {
            const output = JSON.parse(parameter)['output']

            if (data[output[0]][output[1]] && data[output[0]][output[1]][output[2]] !== null) {
                return data[output[0]][output[1]][output[2]]
            }
            return '-'
        },
    },

    mounted() {
        if (this.id_module) {
            this.fetchAssetModuleById()
        }
        this.timer = setInterval(() => {
            this.getFirstDeviceEmission()
        }, 10000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
}
</script>

<style>
.btn-line-break {
    display: inline-block;
    white-space: normal;
    width: 100%;
}

video {
    display: block;
    margin: auto;
}

.v-btn__content {
    width: 100%;
    white-space: normal;
}
</style>
