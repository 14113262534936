<template>
    <div id="installation-step">
        <v-stepper v-model="step" alt-labels class="elevation-0">
            <v-img :src="logo" alt="Logo four data" class="mt-4 img-header" contain max-height="80" />

            <v-row class="justify-center pt-6">
                <span>{{ $t('device_install') }}</span>
            </v-row>

            <v-stepper-header class="elevation-0 stepper-header">
                <v-stepper-step :complete="step > 1" step="1">
                    <span class="text-center">{{ $t('step_1.title') }}</span>
                </v-stepper-step>
                <v-divider />

                <v-stepper-step :complete="step > 2" step="2">
                    <span class="text-center">{{ $t('step_2.title') }}</span>
                </v-stepper-step>
                <v-divider />

                <v-stepper-step :complete="step > 3" step="3">
                    <span class="text-center">{{ !is_bee_2_beep ? $t('step_3.title') : $t('step_4.title') }}</span>
                </v-stepper-step>
                <v-divider v-if="!is_bee_2_beep" />

                <v-stepper-step v-if="!is_bee_2_beep" step="4">
                    <span class="text-center">{{ $t('step_4.title') }} </span>
                </v-stepper-step>
            </v-stepper-header>

            <div class="text-center">
                <p v-if="mobile">{{ $t('step_' + step + '.title') }}</p>
            </div>
            <v-stepper-items>
                <v-stepper-content class="stepper-content-background" step="1">
                    <installation-step-1
                        ref="installationStep1"
                        v-on:goToStepTwoWithUser="goToStepTwoWithUser"
                        v-on:goToStepTwoWithoutUser="goToStepTwoWithoutUser"
                        v-on:previousStep="previousStep"
                    />
                </v-stepper-content>

                <v-stepper-content class="stepper-content-background" step="2">
                    <installation-step-2
                        :device_number_scanned="device_number"
                        v-on:goToStepThree="goToStepThree"
                        v-on:previousStep="previousStep"
                    />
                </v-stepper-content>

                <v-stepper-content class="stepper-content-background" step="3">
                    <installation-step-3
                        v-if="!is_bee_2_beep"
                        :id_professional="id_professional"
                        :name_professional="name_professional"
                        v-on:goToStepFour="goToStepFour"
                        v-on:previousStep="previousStep"
                    />

                    <installation-step-4
                        v-else
                        ref="installationStep4"
                        :asset_type="asset_type"
                        :device_number="device_number"
                        :id_professional="id_professional"
                        :uid_professional="uid_professional"
                        :user="user"
                        v-on:goToResult="goToResult"
                        v-on:previousStep="previousStep"
                    />
                </v-stepper-content>

                <v-stepper-content class="stepper-content-background" step="4">
                    <installation-step-4
                        v-if="!is_bee_2_beep"
                        ref="installationStep4"
                        :asset_type="asset_type"
                        :device_number="device_number"
                        :id_device="id_device"
                        :id_professional="id_professional"
                        :uid_professional="uid_professional"
                        :user="user"
                        v-on:goToResult="goToResult"
                        v-on:previousStep="previousStep"
                    />
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <div v-if="show_loader" class="global-loader">
            <div class="loader" />
        </div>
    </div>
</template>

<script>
import { version } from '../../package.json'
import InstallationStepOneComponent from '../components/installation-step/installation-step-1.vue'
import InstallationStepTwoComponent from '../components/installation-step/installation-step-2.vue'
import InstallationStepThreeComponent from '../components/installation-step/installation-step-3.vue'
import InstallationStepFourComponent from '../components/installation-step/installation-step-4.vue'

export default {
    name: 'InstallationStep',
    components: {
        'installation-step-1': InstallationStepOneComponent,
        'installation-step-2': InstallationStepTwoComponent,
        'installation-step-3': InstallationStepThreeComponent,
        'installation-step-4': InstallationStepFourComponent,
    },
    data() {
        return {
            show_loader: true,
            logo: '',
            url_platform: null,
            step: 1,
            user: null,
            id_device: null,
            device_number: '',
            value_type_device: null,
            url_device_detail: '',
            id_professional: null,
            uid_professional: '',
            name_professional: '',
            asset_type: {
                id_referential: null,
                key: '',
                value: '',
                id_asset_generic_module: null,
                id_module: null,
            },
            installation: {
                notice: '',
                description: '',
                introduction: '',
            },
            type_snackbar: '',
            message: '',
            show_snackbar: false,
        }
    },
    computed: {
        mobile() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        is_bee_2_beep() {
            return this.$store.getters['global/is_bee_2_beep']
        },
    },
    beforeCreate() {
        this.$store.dispatch('autofill/fillParams', this.$route.query)

        let url = '/api/v1/display_option'
        if (this.$route.query.device_number && this.$route.query.device_number !== '') {
            url += '/device/' + this.$route.query.device_number
        } else if (this.$route.params.param !== undefined) {
            url += '/' + this.$route.params.param
        } else {
            url += '/tunnel_installation'
        }

        this.axios
            .get(url)
            .then((success) => {
                this.logo = success.data.data.src_logo
                if (this.device_number === '') {
                    this.url_platform = success.data.data.url_platform
                }

                if (success.data.data.tunnel_installation_url === 'installation.bee2beep.com') {
                    this.$store.dispatch('global/setIsBee2Beep', true)
                    this.asset_type = {
                        id_referential: 230,
                        key: 'generic',
                        value: 'Ruche',
                        id_asset_generic_module: 5,
                    }
                }

                this.$vuetify.theme.themes.light.primary = success.data.data.primary
                document.getElementById('favicon').href = success.data.data.src_favicon
            })
            .catch((error) => {
                this.manageError('warning', this.$t('error_display_option'), error)
            })
            .finally(() => {
                console.log('Store ' + this.$store.getters['global/application_version'])
                console.log('Package version ' + version)
                console.log('Env ' + process.env.NODE_ENV)
                if (this.$store.getters['global/application_version'] === version || process.env.NODE_ENV === 'local') {
                    setTimeout(() => {
                        this.show_loader = false
                    }, 1000)
                } else if (!this.$store.getters['global/application_version']) {
                    //In case current service worker version is already set, but store has been clear
                    this.$store.dispatch('global/setApplicationVersion', version)

                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    this.$store.dispatch('global/setApplicationVersion', version)
                }
            })
    },
    methods: {
        goToStepTwoWithUser(item) {
            this.$store.dispatch('global/with_user')
            this.show_information_message = false
            this.device_number = this.$route.query.device_number
            this.user = item.user
            this.step = 2
        },

        goToStepTwoWithoutUser() {
            this.$store.dispatch('global/without_user')

            if (this.device_number === '' && this.$route.query.device_number && this.$route.query.device_number !== '') {
                this.device_number = this.$route.query.device_number
            }
            this.step = 2
        },

        goToStepThree(item) {
            this.id_professional = item.id_professional
            this.uid_professional = item.uid_professional
            this.id_device = item.id_device
            this.value_type_device = item.value_type_device
            this.device_number = item.device_number
            this.url_device_detail = item.url_device_detail
            this.name_professional = item.name_professional
            this.installation = item.installation
            this.step = 3
        },

        goToStepFour(item) {
            this.asset_type = { ...item.asset_type }
            this.step = 4
            if (this.asset_type.id_module === null) {
                this.nextTick(() => {
                    this.$refs.installationStep4.resetForm()
                })
            }
        },

        goToResult() {
            this.$router.push({
                name: 'result',
                params: {
                    id_device: this.id_device,
                    device_number: this.device_number,
                    url_device_detail: this.url_device_detail,
                    id_professional: this.id_professional,
                    email: this.$refs.installationStep1.email,
                    value_type_device: this.value_type_device,
                    url_platform: this.url_platform,
                    installation: this.installation,
                    id_module: this.asset_type.id_module,
                },
            })
        },

        previousStep() {
            this.step--
        },
    },
    mounted() {
        if (this.$route.query.device_number && this.$route.query.device_number !== '') {
            this.device_number = this.$route.query.device_number
        }
    },
}
</script>

<style scoped>
.img-header {
    max-width: 300px;
    margin: auto;
}

.stepper-content-background {
    background-color: #e5e5e5 !important;
}

.stepper-header {
    max-width: 1000px;
    margin: auto;
}

.v-stepper__step {
    padding: 24px 20px;
}
</style>
