import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const options = {
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#1aa1dc',
                secondary: '#FCFAF1',
                accent: '#D3D3D3',
                error: '#c1201f',
                info: '#32b3ee',
                success: '#169316',
                warning: '#c96d11',
                black: '#000000',
            },
        },
    },
};

export default new Vuetify(options);
