<template>
    <div id="installation-step-three" class="div-height">
        <v-card height="100%">
            <v-card-title>
                <v-icon class="pointer" @click="previousStep()">mdi-arrow-left</v-icon>
                <span class="ml-1">{{ $t('step_3.title') }}</span>
            </v-card-title>
            <v-card-text>
                <v-alert v-if="without_user" color="success" outlined text>
                    {{ $t('step_3.device_associate_to') }}
                    <strong>{{ name_professional }}</strong>
                </v-alert>

                <p>
                    <strong>{{ $t('step_3.choose_equipment_type') }}</strong>
                </p>

                <v-form ref="form">
                    <!-- TODO: Trad module by designation -->
                    <v-select
                        v-model="asset_type"
                        :item-text="(item) => getAssetTypeItemText(item)"
                        :items="asset_types"
                        :label="$t('step_3.equipment_type')"
                        :loading="loading_type_asset"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :no-data-text="$t('step_3.no_equipment_type')"
                        :rules="[(a) => !!a || $t('step_3.equipment_type_required')]"
                        outlined
                        return-object
                    >
                        <template v-slot:item="{ on, attrs, item }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <template v-slot:default>
                                    <div>
                                        <p class="mb-n2">{{ getAssetTypeItemText(item) }}</p>
                                        <span class="caption text--secondary">{{ item.description }}</span>
                                    </div>
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>

                    <v-row>
                        <v-col>
                            <v-btn block class="white--text" color="grey" large @click="previousStep()">
                                {{ $t('back') }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block color="primary" large @click="goToStepFour()">
                                {{ $t('next') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'InstallationStepThreeComponent',
    data() {
        return {
            asset_types: [],
            asset_type: null,
            type_snackbar: '',
            error_snackbar: '',
            loading_type_asset: true,
        }
    },
    props: {
        id_professional: {
            type: Number,
            required: false,
        },
        name_professional: {
            type: String,
            required: false,
        },
    },
    watch: {
        id_professional: function () {
            this.getAssetTypes()
        },
    },
    computed: {
        without_user: function () {
            return this.$store.getters['global/without_user']
        },
    },
    methods: {
        getAssetTypeItemText(item) {
            if (item.id_module) {
                return this.$t(`modules.${item.key}`)
            }
            return item.key !== 'generic' ? this.$t(`step_3.${item.key}`) : item.value
        },
        fillDataFromParamsToStep3() {
            const params = this.$store.getters['autofill/params']
            this.asset_type = this.asset_types.find((type) => type.value === params.type_asset) ?? null
        },

        getAssetTypes() {
            this.loading_type_asset = true

            this.axios
                .get('/api/v1/professional/' + this.id_professional + '/asset_types')
                .then((success) => {
                    this.asset_types = success.data.data
                    this.fillDataFromParamsToStep3()
                    if (this.asset_types.length === 1) {
                        this.asset_type = this.asset_types[0]
                    }
                })
                .catch((error) => {
                    this.manageError('error', this.$t('step_3.error_get_asset_types'), error)
                })
                .finally(() => {
                    this.loading_type_asset = false
                })
        },

        goToStepFour() {
            if (this.$refs.form.validate()) {
                this.$emit('goToStepFour', { asset_type: this.asset_type })
            }
        },

        previousStep() {
            this.$emit('previousStep')
        },
    },
}
</script>

<style>
#installation-step-three {
    max-width: 1000px;
    margin: auto;
}
</style>
