<template>
    <div id="flag-selector">
        <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="selected_flag"
            :items="countries"
            item-text="name"
            @change="updateLanguage()"
            return-object
            solo
            dense
        >
            <template slot="selection" slot-scope="data">
                <country-flag :country='data.item.flag' size='small'/>
            </template>
            <template slot="item" slot-scope="data">
                <country-flag :country='data.item.flag' size='small'/>
                <span class="pl-2">{{ data.item.name }}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';

export default {
    name: 'flag-selector',
    components: {CountryFlag},
    data() {
        return {
            selected_flag: null,
            countries: [
                {
                    name: 'Français',
                    flag: 'fr',
                    language: 'fr-FR'
                },
                {
                    name: 'English',
                    flag: 'gb',
                    language: 'en-gb'
                },
                {
                    name: 'German',
                    flag: 'de',
                    language: 'de-de'
                },
                {
                    name: 'Dutch',
                    flag: 'nl',
                    language: 'nl'
                },
                {
                    name: 'Irish',
                    flag: 'ie',
                    language: 'en-ie'
                },
                {
                    name: 'Italian',
                    flag: 'it',
                    language: 'it'
                },
                {
                    name: 'Spanish',
                    flag: 'es',
                    language: 'es'
                },
                {
                    name: 'Português',
                    flag: 'pt',
                    language: 'pt'
                }
            ],
        }
    },
    methods: {
        updateLanguage() {
            this.$i18n.locale = this.selected_flag.language;
        }
    },
    beforeMount() {
        let selectedFlag = this.countries.filter((country) => {
            if (country.language === this.$i18n.locale) {
                return country;
            }
        })[0];

        if (!selectedFlag) {
            this.selected_flag = {
                name: 'English',
                flag: 'gb-eng',
                language: 'en-gb'
            };
            this.$i18n.locale = this.selected_flag.language;
        } else {
            this.selected_flag = selectedFlag;
        }
    }
}
</script>

<style scoped>

</style>