<template>
    <div id="geolocation">
        <v-alert text color="error" class="text-center" outlined v-if="wait_validation">
            {{ $t('asset_domicile.refuse_localisation') }}
        </v-alert>

        <v-text-field
            v-if="localisation_domicile"
            :label="$t('asset_domicile.address_find')"
            v-model="localisation_domicile"
            class="rounded-0" outlined readonly
        />

        <gmap-map
            ref="map"
            :center="{lat: 0, lng: 0}"
            class="g-map hidden"
        />
    </div>
</template>

<script>
export default {
    name: "geolocation",
    data() {
        return {
            localisation_domicile: null,
            wait_validation: false,
        }
    },
    props: {
        value_type_address: {
            type: Number,
            required: false
        },
    },
    watch: {
        value_type_address: function (value) {
            if (value === 3) {
                this.getCurrentLocation();
            } else {
                this.localisation_domicile = null;
                this.wait_validation = false;
            }
        },
    },
    methods: {
        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    this.getDomicileFromGeocode(position.coords.latitude, position.coords.longitude);
                }, () => {
                    this.wait_validation = true;
                });
            } else {
                this.manageError('error', this.$t('asset_domicile.error_browser_localisation'));
            }
        },

        getDomicileFromGeocode(latitude, longitude) {
            let geocoder = new window.google.maps.Geocoder();
            let location = {lat: latitude, lng: longitude}

            geocoder.geocode({'location': location}, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.constructDomicileObjectWithGeocode(results, latitude, longitude);
                    } else {
                        this.showSnackbar('warning', this.$t('asset_domicile.error_localisation_precision'));
                    }
                } else {
                    this.manageError('error', this.$t('asset_domicile.error_geocode'));
                }
            });
        },

        constructDomicileObjectWithGeocode(results, latitude, longitude) {
            let domicile = {
                street_number: '',
                route: '',
                postal_code: null,
                locality: null,
                country: null,
                latitude: latitude,
                longitude: longitude,
                address2: '',
                country_code: ''
            };

            domicile.address2 = !results[0].formatted_address.includes(results[0].name) ? results[0].name : ''

            results[0].address_components.forEach((data) => {
                if (data.types.includes('street_number')) {
                    domicile.street_number = data.long_name;
                } else if (data.types.includes('route')) {
                    domicile.route = data.long_name;
                } else if (data.types.includes('postal_code')) {
                    domicile.postal_code = data.long_name;
                } else if (data.types.includes('locality')) {
                    domicile.locality = data.long_name;
                } else if (data.types.includes('country')) {
                    domicile.country = data.long_name;
                    domicile.country_code = data.short_name;
                }
            });

            if (domicile.locality === null || domicile.country === null || domicile.postal_code === null) {
                this.showSnackbar('warning', this.$t('asset_domicile.error_localisation_precision'));
                this.$emit('getDomicileFromGeocode', {domicile: null});
            } else {
                domicile.name = domicile.street_number + ' ' + domicile.route;
                this.localisation_domicile = domicile.name + ' ' + domicile.postal_code + ' ' + domicile.locality;
                this.$emit('getDomicileFromGeocode', {domicile: domicile});
            }
        }
    }
}
</script>

<style scoped>

</style>