<template>
    <div id="tank-type">
        <strong>
            <v-switch v-model="tank_specification.know_model_brand_asset" :label="$t('tank_type.know_brand_model')" inset />
        </strong>

        <template v-if="!tank_specification.know_model_brand_asset">
            <v-radio-group v-model="tank_specification.tank_type" :rules="[(l) => !!l || $t('tank_type.tank_type_required')]">
                <v-row>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="3" />
                        <v-img
                            :src="'/images/asset_type/tank/tank_debout_checkbox' + (tank_specification.is_mobile ? '_roue.png' : '.png')"
                            aspect-ratio="1"
                            class="pointer"
                            contain
                            max-height="100"
                            @click="tank_specification.tank_type = '3'"
                        />
                        <p class="text-center pointer" @click="tank_specification.tank_type = '3'">
                            {{ $t('tank_type.vertical_cylindrical_format') }}
                        </p>
                    </v-col>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="1" />
                        <v-img
                            :src="
                                '/images/asset_type/tank/tank_parallelepipedique_checkbox' +
                                (tank_specification.is_mobile ? '_roue.png' : '.png')
                            "
                            aspect-ratio="1"
                            class="pointer"
                            contain
                            max-height="100"
                            @click="tank_specification.tank_type = '1'"
                        />
                        <p class="text-center pointer" @click="tank_specification.tank_type = '1'">
                            {{ $t('tank_type.rectangular_format') }}
                        </p>
                    </v-col>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="4" />
                        <v-img
                            :src="'/images/asset_type/tank/tank_spherique_checkbox' + (tank_specification.is_mobile ? '_roue.png' : '.png')"
                            aspect-ratio="1"
                            class="pointer"
                            contain
                            max-height="100"
                            @click="tank_specification.tank_type = '4'"
                        />
                        <p class="text-center pointer" @click="tank_specification.tank_type = '4'">
                            {{ $t('tank_type.spherical_format') }}
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="5" />
                        <v-img
                            :src="'/images/asset_type/tank/tank_ibc_checkbox' + (tank_specification.is_mobile ? '_roue.png' : '.png')"
                            aspect-ratio="1"
                            class="pointer"
                            contain
                            max-height="100"
                            @click="tank_specification.tank_type = '5'"
                        />
                        <p class="text-center pointer" @click="tank_specification.tank_type = '5'">
                            {{ $t('tank_type.ibc_format') }}
                        </p>
                    </v-col>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="2" />
                        <v-img
                            :src="
                                '/images/asset_type/tank/tank_cylindrique1_checkbox' + (tank_specification.is_mobile ? '_roue.png' : '.png')
                            "
                            aspect-ratio="1"
                            class="pointer"
                            contain
                            max-height="100"
                            @click="tank_specification.tank_type = '2'"
                        />
                        <p class="text-center pointer" @click="tank_specification.tank_type = '2'">
                            {{ $t('tank_type.coated_cylindrical_format') }}
                        </p>
                    </v-col>

                    <v-col />
                </v-row>
            </v-radio-group>

            <v-checkbox v-model="tank_specification.is_mobile" :label="$t('tank_type.is_mobile')" />
        </template>

        <template v-else>
            <v-row>
                <v-col>
                    <v-select
                        v-model="tank_specification.id_brand_reference"
                        :items="asset_brands"
                        :label="$t('tank_type.tank_brand')"
                        :loading="loading_asset_brands"
                        :rules="[(l) => !!l || $t('tank_type.tank_brand_required ')]"
                        item-text="name"
                        item-value="id_brand_reference"
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        @change="getAssetModelsByBrand()"
                    />
                </v-col>

                <v-col>
                    <v-select
                        v-model="tank_specification.id_model_reference"
                        :disabled="disabled_asset_models"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :items="asset_models"
                        :label="$t('tank_type.tank_model')"
                        :loading="loading_asset_models"
                        :rules="[(l) => !!l || $t('tank_type.tank_model_required')]"
                        item-text="trade_name"
                        item-value="id_model_reference"
                        outlined
                    />
                </v-col>
            </v-row>
        </template>
        <template v-if="!tank_specification.know_model_brand_asset">
            <p class="mt-6">
                <strong>{{ $t('tank_type.height_volume_tank') }}</strong>
            </p>

            <v-text-field
                v-model="tank_specification.volume"
                :label="$t('tank_type.volume')"
                :rules="[(l) => !!l || $t('tank_type.volume_required')]"
                class="rounded-0"
                outlined
                type="number"
                @change="prepareCalculationAutocompletionTank('volume')"
            />

            <v-text-field
                v-model="tank_specification.height"
                :label="$t('tank_type.height')"
                :rules="[(l) => !!l || $t('tank_type.height_required')]"
                class="rounded-0"
                outlined
                type="number"
                @change="prepareCalculationAutocompletionTank('height')"
            />

            <v-text-field
                v-model="tank_specification.length"
                :label="$t('tank_type.length_interior')"
                :rules="[(l) => !!l || $t('tank_type.length_interior_required')]"
                class="rounded-0"
                outlined
                type="number"
                @change="prepareCalculationAutocompletionTank('length')"
            />

            <v-text-field
                v-model="tank_specification.width"
                :label="$t('tank_type.width_interior')"
                :rules="[(l) => !!l || $t('tank_type.width_interior_required')]"
                class="rounded-0"
                outlined
                type="number"
                @change="prepareCalculationAutocompletionTank('width')"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'tank-type',
    data() {
        return {
            asset_brands: [],
            loading_asset_brands: false,
            asset_models: [],
            disabled_asset_models: true,
            loading_asset_models: false,

            properties: ['volume', 'height', 'length', 'width'],

            tank_specification: {
                know_model_brand_asset: false,
                id_brand_reference: null,
                id_model_reference: null,
                tank_type: null,
                is_mobile: false,
                volume: null,
                height: null,
                length: null,
                width: null,
            },
        }
    },
    watch: {
        'tank_specification.tank_type'() {
            this.refreshPropertyValueIfTankTypeChange()
        },
    },
    created() {
        this.getAssetBrandReferences()
    },
    methods: {
        fillDataFromParamsToStepsTankType() {
            const params = this.$store.getters['autofill/params']
            this.tank_specification.know_model_brand_asset = parseInt(params.know_model_brand_asset) ? true : false
            this.tank_specification.id_brand_reference =
                this.asset_brands.find((brand) => brand.name === params.brand_name)?.id_brand_reference ?? null
            if (this.tank_specification.id_brand_reference) {
                this.getAssetModelsByBrand().finally(() => {
                    this.tank_specification.id_model_reference =
                        this.asset_models.find((model) => model.trade_name === params.model_name)?.id_model_reference ?? null
                })
            }

            this.tank_specification.volume = params.volume ?? null
            this.tank_specification.height = params.height ?? null
            this.tank_specification.length = params.length ?? null
            this.tank_specification.width = params.width ?? null
        },

        getAssetBrandReferences() {
            this.loading_asset_brands = true

            this.axios
                .get('/api/v1/brand_reference?type=CUVE')
                .then((success) => {
                    this.asset_brands = success.data.data
                })
                .catch((error) => {
                    this.manageError('error', this.$t('tank_type.error_get_tank_brands'), error)
                })
                .finally(() => {
                    this.loading_asset_brands = false
                    this.fillDataFromParamsToStepsTankType()
                })
        },

        getAssetModelsByBrand() {
            this.disabled_asset_models = false
            this.loading_asset_models = true

            return this.axios
                .get('/api/v1/model_reference/' + this.tank_specification.id_brand_reference)
                .then((success) => {
                    this.asset_models = success.data.data
                })
                .catch((error) => {
                    this.manageError('error', this.$t('tank_type.error_get_tank_models'), error)
                })
                .finally(() => {
                    this.loading_asset_models = false
                })
        },

        getTotalPropertySet() {
            let totalPropertySet = 0
            this.properties.forEach((property_name) => {
                if (this.tank_specification[property_name] !== '' && this.tank_specification[property_name] !== null) {
                    totalPropertySet++
                }
            })

            return totalPropertySet
        },

        getPropertyNotSet() {
            let propertyNotSet = ''
            this.properties.forEach((property) => {
                if (this.tank_specification[property] === '' || this.tank_specification[property] === null) {
                    propertyNotSet = property
                }
            })

            return propertyNotSet
        },

        prepareCalculationAutocompletionTank(property_name) {
            if (this.tank_specification.tank_type !== null) {
                switch (this.tank_specification.tank_type) {
                    case '1': {
                        this.calculateRectangularType(property_name)
                        break
                    }
                    case '2': {
                        this.calculateCylindricalHorizontalType(property_name)
                        break
                    }
                    case '3': {
                        this.calculateCylindricalVerticalType(property_name)
                        break
                    }
                    case '4': {
                        this.calculateSphericalType(property_name)
                        break
                    }
                    case '5': {
                        this.calculateIbcType(property_name)
                        break
                    }
                }
            }
        },

        calculateRectangularType(property_name) {
            if (this.getTotalPropertySet() === 3) {
                if (this.tank_specification.volume === null && this.tank_specification.volume === '') {
                    this.tank_specification.volume = Math.floor(
                        (this.tank_specification.width * this.tank_specification.height * this.tank_specification.length) / 1000,
                    )
                } else {
                    let propertyValue = 1
                    this.properties.forEach((property) => {
                        if (
                            this.tank_specification[property] !== '' &&
                            this.tank_specification[property] !== null &&
                            property !== 'volume'
                        ) {
                            propertyValue = propertyValue * this.tank_specification[property]
                        }
                    })
                    this.tank_specification[this.getPropertyNotSet()] = Math.floor((this.tank_specification.volume * 1000) / propertyValue)
                }
            }

            if (this.getTotalPropertySet() === 4) {
                if (property_name === 'width') {
                    this.tank_specification.volume = Math.floor(
                        (this.tank_specification.width * this.tank_specification.height * this.tank_specification.length) / 1000,
                    )
                } else {
                    this.tank_specification.width = Math.floor(
                        (this.tank_specification.volume * 1000) / (this.tank_specification.length * this.tank_specification.height),
                    )
                }
            }
        },

        calculateCylindricalHorizontalType(property_name) {
            if (property_name === 'height' && this.tank_specification.height) {
                this.tank_specification.width = this.tank_specification.height
            } else if (property_name === 'width' && this.tank_specification.width) {
                this.tank_specification.height = this.tank_specification.width
            }

            if (this.tank_specification.volume && this.tank_specification.height && this.tank_specification.width) {
                switch (property_name) {
                    case 'length':
                        this.tank_specification.height = this.calculateByVolumeAndX(
                            this.tank_specification.volume,
                            this.tank_specification.length,
                        )
                        this.tank_specification.width = this.tank_specification.height
                        break
                    case 'volume':
                    case 'height':
                        this.tank_specification.length = this.calculateByVolumeAndY(
                            this.tank_specification.volume,
                            this.tank_specification.height,
                        )
                        break
                }
            }

            if (this.tank_specification.volume && this.tank_specification.height && !this.tank_specification.length) {
                this.tank_specification.length = this.calculateByVolumeAndY(this.tank_specification.volume, this.tank_specification.height)
            } else if (!this.tank_specification.volume && this.tank_specification.height && this.tank_specification.length) {
                this.tank_specification.volume = this.calculateByWidthAndLength(
                    this.tank_specification.height,
                    this.tank_specification.length,
                )
            } else if (this.tank_specification.volume && this.tank_specification.length && this.tank_specification.height) {
                this.tank_specification.height = this.calculateByVolumeAndX(this.tank_specification.volume, this.tank_specification.length)
            }
        },

        calculateCylindricalVerticalType(property_name) {
            if (property_name === 'width' && this.tank_specification.width) {
                this.tank_specification.length = this.tank_specification.width
            } else if (property_name === 'length' && this.tank_specification.length) {
                this.tank_specification.width = this.tank_specification.length
            }

            if (this.tank_specification.volume && this.tank_specification.height && this.tank_specification.width) {
                switch (property_name) {
                    case 'width':
                        this.tank_specification.height = this.calculateByVolumeAndY(
                            this.tank_specification.volume,
                            this.tank_specification.width,
                        )
                        break
                    case 'volume':
                    case 'length':
                    case 'height':
                        this.tank_specification.width = this.calculateByVolumeAndX(
                            this.tank_specification.volume,
                            this.tank_specification.height,
                        )
                        this.tank_specification.length = this.tank_specification.width
                        break
                }
            }

            if (this.tank_specification.volume && this.tank_specification.length && !this.tank_specification.width) {
                this.tank_specification.width = this.calculateByVolumeAndX(this.tank_specification.volume, this.tank_specification.height)
                this.tank_specification.length = this.tank_specification.width
            } else if (!this.tank_specification.volume && this.tank_specification.height && this.tank_specification.width) {
                this.tank_specification.volume = this.calculateByWidthAndLength(
                    this.tank_specification.width,
                    this.tank_specification.height,
                )
            } else if (this.tank_specification.volume && this.tank_specification.width && !this.tank_specification.length) {
                this.tank_specification.height = this.calculateByVolumeAndY(this.tank_specification.volume, this.tank_specification.width)
            }
        },

        calculateByVolumeAndY(x, y) {
            return Math.floor((x * 1000) / (Math.PI * ((y / 2) * (y / 2))))
        },

        calculateByVolumeAndX(x, y) {
            return Math.floor(2 * Math.sqrt((x * 1000) / (Math.PI * y)))
        },

        calculateByWidthAndLength(x, y) {
            return Math.floor((Math.PI * (x / 2) * (x / 2) * y) / 1000)
        },

        calculateSphericalType(property_name) {
            if (this.getTotalPropertySet() >= 2) {
                if (property_name === 'volume') {
                    this.setPropertyValueTank(Math.round(Math.pow((this.tank_specification.volume * 3) / (4 * Math.PI), 1 / 3) * 20))
                } else {
                    this.tank_specification.volume = Math.round(
                        (4 * Math.PI * Math.pow(this.tank_specification[property_name] / 2, 3)) / 3000,
                    )
                    this.setPropertyValueTank(this.tank_specification[property_name])
                }
            }
        },

        calculateIbcType(property_name) {
            if (this.getTotalPropertySet() >= 2) {
                if (property_name === 'volume') {
                    this.setPropertyValueTank(Math.round((Math.round(Math.pow(this.tank_specification.volume, 1 / 3) * 100) / 100) * 10))
                } else {
                    this.tank_specification.volume = Math.round(Math.pow(this.tank_specification[property_name], 3) / 1000)
                    this.setPropertyValueTank(this.tank_specification[property_name])
                }
            }
        },

        setPropertyValueTank(property_value) {
            this.tank_specification.height = property_value
            this.tank_specification.length = property_value
            this.tank_specification.width = property_value
        },

        refreshPropertyValueIfTankTypeChange() {
            if (this.getTotalPropertySet() >= 2) {
                this.prepareCalculationAutocompletionTank('volume')
            }
        },
    },
}
</script>

<style scoped>
.align-center-radio >>> div {
    margin-right: 0 !important;
}
</style>
