<template>
    <div v-if="this.showMap">
        <gmap-map
            ref="map"
            :center="center"
            :zoom="zoom"
            class="g-map"
            @click="clickOnMap($event)"
        >
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
            />
        </gmap-map>
    </div>
</template>

<script>
export default {
    name: "selection-on-map",
    props: {
        latitude: Number,
        longitude: Number,
        zoom: Number,
        showMap: Boolean,
    },
    data() {
        return {
            center: {lat: this.latitude, lng: this.longitude},
            markers: [],
        }
    },
    watch: {
        latitude: function () {
            this.setCenter(this.latitude, this.longitude);
        },

        longitude: function () {
            this.setCenter(this.latitude, this.longitude);
        },
    },

    methods: {
        setCenter(latitude, longitude) {
            this.center = {lat: latitude, lng: longitude};
            this.addMarker(latitude, longitude);
        },

        addMarker(latitude, longitude) {
            const marker = {
                lat: latitude,
                lng: longitude,
            };
            this.markers = [];
            this.markers.push({position: marker});
        },

        clickOnMap(event) {
            let geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({'location': event.latLng}, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.constructDomicileObjectWithGeocode(results);
                    } else {
                        this.showSnackbar('warning', this.$t('asset_domicile.bad_marker'));
                    }
                } else {
                    this.manageError('error', this.$t('asset_domicile.error_geocode'));
                }
            });

            this.addMarker(event.latLng.lat(), event.latLng.lng());
        },

        constructDomicileObjectWithGeocode(results) {
            let domicile = {
                street_number: '',
                route: '',
                postal_code: null,
                locality: null,
                country: null,
                country_code: null
            };


            results[0].address_components.forEach((data) => {
                if (data.types.includes('street_number')) {
                    domicile.street_number = data.long_name;
                } else if (data.types.includes('route')) {
                    domicile.route = data.long_name;
                } else if (data.types.includes('postal_code')) {
                    domicile.postal_code = data.long_name;
                } else if (data.types.includes('locality')) {
                    domicile.locality = data.long_name;
                } else if (data.types.includes('country')) {
                    domicile.country = data.long_name;
                    domicile.country_code = data.short_name;
                }
            });

            domicile.latitude = this.latitude;
            domicile.longitude = this.longitude;

            if (domicile.locality === null || domicile.country === null || domicile.postal_code === null) {
                this.showSnackbar('warning', this.$t('asset_domicile.bad_marker'));
                this.$emit('getDomicileFromGeocode', {domicile: null});
            } else {
                domicile.name = domicile.street_number + ' ' + domicile.route;
                this.$emit('getDomicileFromGeocode', {domicile: domicile});
            }
        }
    }
}
</script>

<style scoped>
.g-map {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}
</style>