const state = () => ({
    params: {},
});

const mutations = {
    fillParams(state, params) {
        state.params = params;
    },
};

const actions = {
    fillParams({commit}, params) {
        commit('fillParams', params);
    }
};

const getters = {
    params: (state) => state.params,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
