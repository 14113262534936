<template>
    <div id="add-client-dialog-component">
        <v-dialog v-model="is_add_edit_client_dialog_open" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('add_client_dialog_component.add_client') }}
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            v-model.trim="client.company_name"
                            :label="$t('add_client_dialog_component.company_name')"
                            :rules="[(v) => !!v || this.$t('generic_form_component.required_fields')]"
                            autofocus
                            filled
                        />

                        <v-text-field
                            v-model.trim="client.client_space_name"
                            :label="$t('add_client_dialog_component.client_space_name')"
                            filled
                        />

                        <v-text-field v-model.trim="client.siret" :label="$t('add_client_dialog_component.siren')" filled />

                        <v-text-field
                            v-model.trim="client.code_client"
                            :label="$t('add_client_dialog_component.client_reference')"
                            filled
                        />

                        <v-btn block color="primary" @click.prevent="prepareUpdateClient()" large :loading="editing_client">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block large text @click="is_add_edit_client_dialog_open = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'AddEditClientDialogComponent',
    data() {
        return {
            is_add_edit_client_dialog_open: false,
            client: {},
            editing_client: false,
            uid_professional: 0,
        }
    },
    methods: {
        openAddClientDialog(uid_professional) {
            this.client = {
                company_name: '',
                client_space_name: '',
                siret: '',
                code_client: '',
            }
            this.uid_professional = uid_professional
            this.is_add_edit_client_dialog_open = true
        },

        async prepareUpdateClient() {
            if (await this.$refs.form.validate()) {
                this.addClient()
            }
        },

        addClient() {
            this.editing_client = true
            this.axios
                .post(`/api/v1/client/${this.uid_professional}`, {
                    siret: this.client.siret,
                    numero_professionnel: this.client.code_client,
                    nom_entreprise: this.client.company_name,
                    client_space_name: this.client.client_space_name,
                })
                .then(() => {
                    this.showSnackbar('success', this.$t('add_client_dialog_component.added_client'))
                    this.$emit('added:client', this.client)
                    this.is_add_edit_client_dialog_open = false
                })
                .catch((error) => {
                    this.manageError('error', '', error)
                })
                .finally(() => {
                    this.editing_client = false
                })
        },
    },
}
</script>
