<template>
    <v-app class="global">
        <v-main>
            <router-view/>

            <snackbar/>
        </v-main>
    </v-app>
</template>

<script>
import Vue from 'vue';
import Snackbar from './components/global/snackbar.vue';

export default Vue.extend({
    name: 'App',
    components: {Snackbar},
    data() {
        return {
            registration: null,
        }
    },
    created() {
        document.addEventListener('serviceWorkerUpdateEvent', this.updateAvailable, {once: true})
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
        });
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
            }
        }
    }
});
</script>

<style scoped>
.global {
    background-color: #E5E5E5 !important;
}
</style>