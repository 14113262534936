<template>
    <div id="installation-result">
        <v-card class="ma-4" height="100%">
            <v-card-text>
                <template v-if="value_type_device">
                    <installation-result-generic
                        :device_number="device_number"
                        :email="email"
                        :id_device="id_device"
                        :installation_description="installation.description"
                        :installation_notice="installation.notice"
                        :introduction="installation.introduction"
                        :loading_upload_image="loading_upload_image"
                        :id_module="id_module"
                        v-on:navigateToDeviceDetail="navigateToDeviceDetail"
                        v-on:showCam="showCam"
                    />
                </template>
            </v-card-text>
        </v-card>

        <div v-if="show_camera" class="camera">
            <video v-if="!imageData" ref="video" class="camera-stream" />
            <v-img v-else :src="imageData" aspect-ratio="1" class="camera-stream" contain/>

            <div class="camera-btn-list">
                <v-btn class="camera-btn" @click="unshowCam()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-btn v-if="!imageData" class="camera-btn" @click="captureImage()">
                    <v-icon>mdi-camera</v-icon>
                </v-btn>

                <v-btn v-if="imageData" class="camera-btn" @click="validateImage()">
                    <v-icon>mdi-check</v-icon>
                </v-btn>

                <v-btn v-if="imageData" class="camera-btn" @click="cancelImage()">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import InstallationResultGeneric from '../components/installation-result/installation-result-generic'

export default {
    name: 'InstallationResult',
    components: { InstallationResultGeneric },
    data() {
        return {
            email: '',
            id_device: null,
            id_professional: null,
            value_type_device: null,
            device_number: '',
            url_device_detail: '',
            url_platform: '',
            disable_button_add_image: false,

            show_camera: false,
            loading_upload_image: false,
            media_stream: null,
            imageData: '',
            id_module: null,
            installation: {
                notice: '',
                description: '',
                introduction: '',
            },
        }
    },
    methods: {
        sendInvitation() {
            this.axios
                .post('/api/v1/user_client/invitation/inscription', {
                    id_professional: this.id_professional,
                    email: this.email,
                    device_number: this.device_number,
                })
                .then(() => {
                    this.showSnackbar('success', this.$t('installation_result.email_inscription_sent'))
                })
                .catch((error) => {
                    this.manageError('error', this.$t('installation_result.error_email_inscription_sent'), error)
                })
        },

        navigateToDeviceDetail() {
            if (this.url_platform === null) {
                window.location.href = this.url_device_detail
            } else {
                window.location.href = this.url_platform
            }
        },

        showCam() {
            this.show_camera = true

            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            facingMode: {
                                exact: 'environment',
                            },
                        },
                    })
                    .then((mediaStream) => {
                        this.$refs.video.srcObject = mediaStream
                        this.$refs.video.play()
                        this.media_stream = mediaStream
                    })
            } else {
                this.showSnackbar('success', this.$t('installation_result.camera_not_supported'))
            }
        },

        unshowCam() {
            this.show_camera = false
            this.stopMediaStream()
        },

        captureImage() {
            const mediaStreamTrack = this.media_stream.getVideoTracks()[0]
            const imageCapture = new window.ImageCapture(mediaStreamTrack)
            let reader = new FileReader()

            return imageCapture.takePhoto().then((blob) => {
                reader.readAsDataURL(blob)
                reader.onload = () => {
                    this.imageData = reader.result
                }
                this.stopMediaStream()
            })
        },

        validateImage() {
            this.show_camera = false
            this.loading_upload_image = true

            this.axios
                .post('/api/v1/asset/device/' + this.device_number + '/image', {
                    base64_string: this.imageData,
                })
                .then(() => {
                    this.showSnackbar('success', this.$t('installation_result.add_image_succeed'))
                })
                .catch((error) => {
                    this.manageError('error', this.$t('installation_result.error_add_image'), error)
                })
                .finally(() => {
                    this.loading_upload_image = false
                })
        },

        cancelImage() {
            this.imageData = ''
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        facingMode: {
                            exact: 'environment',
                        },
                    },
                })
                .then((mediaStream) => {
                    this.$refs.video.srcObject = mediaStream
                    this.$refs.video.play()
                    this.media_stream = mediaStream
                })
        },

        stopMediaStream() {
            const stream = this.$refs.video.srcObject
            const tracks = stream.getTracks()

            tracks.forEach(function (track) {
                track.stop()
            })
            this.$refs.video.srcObject = null
        },
    },

    mounted() {
        this.id_device = this.$route.params.id_device
        this.device_number = this.$route.params.device_number
        this.url_device_detail = this.$route.params.url_device_detail
        this.id_professional = this.$route.params.id_professional
        this.value_type_device = this.$route.params.value_type_device
        this.id_module = this.$route.params.id_module
        this.url_platform = this.$route.params.url_platform
        this.installation = this.$route.params.installation

        if (
            this.id_device === undefined ||
            this.device_number === undefined ||
            this.url_device_detail === undefined ||
            this.value_type_device === undefined
        ) {
            this.$router.push({ name: 'installation' })
        }

        this.email = this.$route.params.email
        if (this.$store.getters['global/is_send_sign_up_mail'] && this.email !== '') {
            this.sendInvitation()
        }
    },
}
</script>

<style>
#installation-result {
    max-width: 1000px;
    margin: auto;
}

.camera {
    position: absolute;
    inset: 0;
}

.camera .camera-btn-list {
    position: absolute;
    bottom: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.camera-btn-list .camera-btn {
    margin: 8px;
    border-radius: 100%;
}

.camera .camera-stream {
    height: 100%;
    width: 100%;
    background-color: black;
}
</style>
