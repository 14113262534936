<template>
    <div class="fullscreen">
        <stream-barcode-reader @decode="onDecode"/>

        <button class="fullscreen-button" @click="closeQrCodeReader()">
            <img src="../../../public/images/back_arrow.png" alt="toggle fullscreen"/>
        </button>
    </div>
</template>

<script>
import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
    name: "scanner",
    data() {
        return {
            isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices
        };
    },
    components: {StreamBarcodeReader},
    methods: {
        onDecode(decodedString) {
            this.$emit("getDeviceNumber", {device_number: decodedString});
        },

        closeQrCodeReader() {
            this.$emit("closeQrCodeReader");
        }
    },
    mounted() {
        if (!this.isMediaStreamAPISupported) {
            this.showSnackbar('error', this.$t('step_2.camera_error'));
            this.$emit("closeQrCodeReader");
        }
    }
}
</script>

<style>
.fullscreen > div {
    height: 100%;
}

.fullscreen > div > div {
    height: 100%;
}

.fullscreen > div > div > video {
    height: 100%;
    width: 100%;
}


.laser, .overlay-element {
    display: none;
}

.fullscreen > div > div > .overlay-element {
    height: 100%;
}

.fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: black;
}

.fullscreen-button {
    background-color: lightgray;
    position: absolute;
    bottom: 10px;
    right: 10px;
    margin: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.fullscreen-button img {
    padding-top: 5px;
    width: 1.5rem;
}
</style>