<template>
    <div id="silo_type">
        <strong>
            <v-switch
                v-model="silo_specification.know_model_brand_asset" inset
                :label="$t('silo_type.know_brand_model')"
            />
        </strong>

        <template v-if="!silo_specification.know_model_brand_asset">
            <v-radio-group v-model="silo_specification.silo_type"
                           :rules="[l => !!l || $t('silo_type.silo_type_required')]">
                <v-row>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="7"/>
                        <v-img aspect-ratio="1" max-height="100" contain class="pointer"
                               :src="'/images/asset_type/silo/choix-forme-cylindrique.png'"
                               @click="silo_specification.silo_type = '7'"
                        />
                        <p class="text-center pointer" @click="silo_specification.silo_type = '7'">
                            {{ $t('silo_type.rectangular_tower_flat_bottom') }}
                        </p>
                    </v-col>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="8"/>
                        <v-img aspect-ratio="1" max-height="100" contain class="pointer"
                               :src="'/images/asset_type/silo/choix-tour-arrondie.png'"
                               @click="silo_specification.silo_type = '8'"
                        />
                        <p class="text-center pointer" @click="silo_specification.silo_type = '8'">
                            {{ $t('silo_type.cylindrical_tower_bottom') }}
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="9"/>
                        <v-img aspect-ratio="1" max-height="100" contain class="pointer"
                               :src="'/images/asset_type/silo/choix-forme-cylindrique-conique.png'"
                               @click="silo_specification.silo_type = '9'"
                        />
                        <p class="text-center pointer" @click="silo_specification.silo_type = '9'">
                            {{ $t('silo_type.cylindrical_silo_conical_bottom') }}
                        </p>
                    </v-col>
                    <v-col>
                        <v-radio class="justify-center align-center-radio" value="10"/>
                        <v-img aspect-ratio="1" max-height="100" contain class="pointer"
                               :src="'/images/asset_type/silo/choix-forme-cylindrique-para.png'"
                               @click="silo_specification.silo_type = '10'"
                        />
                        <p class="text-center pointer" @click="silo_specification.silo_type = '10'">
                            {{ $t('silo_type.rectangular_silo_pyramidal_bottom') }}
                        </p>
                    </v-col>
                </v-row>
            </v-radio-group>
        </template>

        <template v-else>
            <v-row>
                <v-col>
                    <v-select
                        :label="$t('silo_type.brand_silo')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :items="asset_brands"
                        v-model="silo_specification.id_brand_reference"
                        :rules="[l => !!l || $t('silo_type.brand_silo_required')]"
                        item-text="name"
                        item-value="id_brand_reference"
                        @change="getAssetModelsByBrand()"
                        :loading="loading_asset_brands" outlined
                    />
                </v-col>
                <v-col>
                    <v-select
                        :label="$t('silo_type.model_silo')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :items="asset_models"
                        v-model="silo_specification.id_model_reference"
                        :rules="[l => !!l || $t('silo_type.model_silo_required')]"
                        item-text="trade_name"
                        item-value="id_model_reference"
                        :disabled="disabled_asset_models"
                        :loading="loading_asset_models" outlined
                    />
                </v-col>
            </v-row>
        </template>

        <p class="mt-6">
            <strong>{{ $t('silo_type.height_volume_silo') }}</strong>
        </p>

        <v-text-field
            :label="$t('silo_type.volume')"
            :rules="[l => !!l || $t('silo_type.volume_required')]"
            v-model="silo_specification.volume"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            :label="$t('silo_type.height_total')"
            :rules="[l => !!l || $t('silo_type.height_required')]"
            v-model="silo_specification.height"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            v-if="silo_specification.silo_type === '7' || silo_specification.silo_type === '10'"
            :label="$t('silo_type.length_right_part')"
            :rules="[l => !!l || $t('silo_type.length_required')]"
            v-model="silo_specification.length_right_side"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            v-if="silo_specification.silo_type === '7' || silo_specification.silo_type === '10'"
            :label="$t('silo_type.width_right_part')"
            :rules="[l => !!l || $t('silo_type.width_required')]"
            v-model="silo_specification.width_right_side"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            v-if="silo_specification.silo_type === '8'|| silo_specification.silo_type === '9'"
            :label="$t('silo_type.diameter_part_right')"
            :rules="[l => !!l || $t('silo_type.diameter_required')]"
            v-model="silo_specification.diameter_right_side"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            v-if="silo_specification.silo_type === '9' || silo_specification.silo_type === '10'"
            :label="$t('silo_type.length_cone')"
            :rules="[l => !!l || $t('silo_type.length_cone_required')]"
            v-model="silo_specification.cone_height"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            v-if="silo_specification.silo_type === '10'"
            :label="$t('silo_type.width_min_cone')"
            :rules="[l => !!l || $t('silo_type.width_min_cone_required')]"
            v-model="silo_specification.width_min_cone"
            class="rounded-0" outlined
            type="number"
        />

        <v-text-field
            v-if="silo_specification.silo_type === '9' || silo_specification.silo_type === '10'"
            :label="$t('silo_type.length_width_minimum_cone')"
            :rules="[l => !!l || $t('silo_type.value_required')]"
            v-model="silo_specification.length_diameter_min_cone"
            class="rounded-0" outlined
            type="number"
        />
    </div>
</template>

<script>
export default {
    name: 'silo-type',
    data() {
        return {
            asset_brands: [],
            loading_asset_brands: false,
            asset_models: [],
            disabled_asset_models: true,
            loading_asset_models: false,

            silo_specification: {
                know_model_brand_asset: false,
                id_brand_reference: null,
                id_model_reference: null,
                silo_type: null,
                volume: null,
                height: null,
                diameter_right_side: null,
                length_right_side: null,
                width_right_side: null,
                cone_height: null,
                width_min_cone: null,
                length_diameter_min_cone: null,
            },
        }
    },
    created() {
        this.getAssetBrandReferences();
    },
    methods: {
        fillDataFromParamsToStepsSiloType() {
            const params = this.$store.getters['autofill/params']
            this.silo_specification.know_model_brand_asset = parseInt(params.know_model_brand_asset) ? true : false
            this.silo_specification.id_brand_reference = this.asset_brands.find(brand => brand.name === params.brand_name)?.id_brand_reference ?? null
            if(this.silo_specification.id_brand_reference) {
                this.getAssetModelsByBrand().finally(() => {
                    this.silo_specification.id_model_reference = this.asset_models.find(model => model.trade_name === params.model_name)?.id_model_reference ?? null
                })
            }

            this.silo_specification.volume = params.volume ?? null
            this.silo_specification.height = params.height ?? null
        },

        getAssetBrandReferences() {
            this.loading_asset_brands = true;

            this.axios.get(
                '/api/v1/brand_reference?type=SILO'
            ).then((success) => {
                this.asset_brands = success.data.data;
            }).catch((error) => {
                this.manageError('error', this.$t('silo_type.error_get_silo_brands'), error);
            }).finally(() => {
                this.loading_asset_brands = false;
                this.fillDataFromParamsToStepsSiloType()
            });
        },

        async getAssetModelsByBrand() {
            this.disabled_asset_models = false;
            this.loading_asset_models = true;

            return this.axios.get(
                '/api/v1/model_reference/' + this.silo_specification.id_brand_reference
            ).then((success) => {
                this.asset_models = success.data.data;
            }).catch((error) => {
                this.manageError('error', this.$t('silo_type.error_get_silo_models'), error);
            }).finally(() => {
                this.loading_asset_models = false;
            });
        },
    }
}
</script>

<style scoped>
.align-center-radio >>> div {
    margin-right: 4px;
}
</style>