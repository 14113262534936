<template>
    <div id="asset-generic">
        <v-radio-group v-model="generic_specification.generic_type"
                       :rules="[l => !!l || $t('generic_type.equipment_type_required')]" class="mb-4">
            <v-row>
                <template v-if="asset_generic_module !== null">
                    <v-col cols="4" v-for="asset_generic_model in asset_generic_module.asset_generic_models"
                           :key="asset_generic_model.id_asset_generic_model"
                    >
                        <v-radio class="justify-center align-center-radio"
                                 :key="asset_generic_model.uid"
                                 :value="asset_generic_model.id_asset_generic_model"
                                 @click="generateForm(asset_generic_model)"
                        />
                        <v-img aspect-ratio="1" max-height="100" contain class="pointer"
                               :key="asset_generic_model.path_model"
                               :src="asset_generic_model.path_model"
                               @click="generateForm(asset_generic_model)"
                        />
                        <p class="text-center pointer" :key="asset_generic_model.name"
                           @click="generateForm(asset_generic_model)"
                        >
                            {{ asset_generic_model.name }}
                        </p>
                    </v-col>
                </template>
            </v-row>
        </v-radio-group>

        <v-text-field
            v-for="input in input_int_string"
            :key="input.name"
            v-model="generic_specification[input.name]"
            :label="input.label"
            :rules="input.required === 1 ? [l => !!l || $t('generic_type.field_required')] : []"
            class="rounded-0" outlined
            :type="input.type === 'int' ? 'number' : 'text'"
        />

        <v-checkbox
            class="mt-0 pt-0 mb-4"
            v-for="input in input_boolean"
            :key="input.name"
            v-model="generic_specification[input.name]"
            :label="input.label"
        />

        <v-menu
            v-for="input in input_datetime"
            :key="input.name"
            transition="scale-transition"
            min-width="auto" offset-y
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :label="input.label"
                    v-model="generic_specification[input.name]"
                    :rules="input.required === 1 ?[l => !!l || $t('generic_type.field_required')] : []"
                    outlined v-bind="attrs" v-on="on"
                    readonly
                />
            </template>

            <v-date-picker
                @change="setDateFormat(input.name)"
                v-model="generic_specification[input.name]"
                date-format="dd/MM/yyyy"
            />
        </v-menu>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: 'asset-generic',
    data() {
        return {
            asset_generic_module: null,
            loading_asset_generic_models: false,
            model_form: [],
            generic_specification: {
                id_asset_generic_module: this.id_asset_generic_module,
                generic_type: null
            },
        }
    },
    props: {
        id_asset_generic_module: {
            type: Number,
            required: true
        },
    },
    computed: {
        input_int_string: function () {
            return this.model_form.filter(input => input.type === 'string' || input.type === 'int');
        },
        input_boolean: function () {
            return this.model_form.filter(input => input.type === 'boolean');
        },
        input_datetime: function () {
            return this.model_form.filter(input => input.type === 'datetime');
        }
    },
    methods: {
        getAssetGenericModule() {
            this.loading_asset_generic_models = true;

            this.axios.get(
                '/api/v1/asset_generic_module/' + this.id_asset_generic_module
            ).then((success) => {
                this.asset_generic_module = success.data.data;
            }).catch((error) => {
                this.manageError('error', this.$t('generic_type.problem_equipment_model'), error);
            }).finally(() => {
                this.loading_type_asset = false;
            });
        },

        generateForm(assetGenericModel) {
            this.generic_specification.generic_type = assetGenericModel.id_asset_generic_model;
            this.model_form = [];

            let jsonFormInput = assetGenericModel.form_inputs;
            Object.keys(jsonFormInput).forEach((input) => {
                if (jsonFormInput[input].type === 'boolean') {
                    this.generic_specification[input] = false;
                } else {
                    this.generic_specification[input] = null;
                }

                this.model_form.push(jsonFormInput[input]);
            });
        },

        setDateFormat(inputName) {
            this.generic_specification[inputName] = moment(this.generic_specification[inputName]).format('DD/MM/YYYY');
        }
    },
    mounted() {
        this.getAssetGenericModule();
    }
}
</script>

<style scoped>
.align-center-radio >>> div {
    margin-right: 0;
}
</style>
