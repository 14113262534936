export default {
    data: () => ({
        drawer: true,
        is_online: navigator.onLine,
    }),
    watch: {
        is_online() {
            if (!this.is_online) {
                this.showSnackbar('error', this.$t('no_network'));
            }
        }
    },
    methods: {
        manageError(type, text, error = null) {
            if (!this.is_online) {
                this.showSnackbar('error', this.$t('no_network'));
            } else {
                if (type === undefined || text === undefined) {
                    type = 'error';
                    text = this.$t('error');
                }

                if (!error.response) {
                    //If is it an error js
                    this.postElasticSearchLogFront(error)
                }

                this.showSnackbar(type, text)
            }
        },

        async postElasticSearchLogFront(error) {
            let ip_client = 'unknown'
            await this.axios.get('https://api.ipify.org?format=json', {
                transformRequest: (data, headers) => {
                    delete headers['Authorization']
                },
            })
                .then((success) => {
                    ip_client = success.data.ip
                })

            await this.axios.post(`${process.env.VUE_APP_API_URL}api/v1/elastic_search/log`, {
                error: error.message ?? 'empty',
                message: error.stack ?? 'empty',
                ip_client: ip_client,
            })
        },

        showSnackbar(type, text) {
            this.$store.dispatch('snackbar/showSnackbar', {snackbar_type: type, snackbar_text: text});
        },
    },
    mounted() {
        window.addEventListener('online', () => {
            this.is_online = navigator.onLine;
        });
        window.addEventListener('offline', () => {
            this.is_online = navigator.onLine;
        });
    },
}