import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import snackbar from './modules/snackbar';
import global from './modules/global';
import autofill from './modules/autofill';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    mutations: {},
    modules: {snackbar, global, autofill},
    plugins: [createPersistedState()],
});

export default store;
