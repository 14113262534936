<template>
    <div id="asset-specification">
        <p>
            <strong>{{ $t('asset_specification.equipment_specifications') }}</strong>
        </p>

        <v-text-field
            :label="$t('asset_specification.offset')"
            :rules="[l => (!!l || l === 0) || $t('asset_specification.offset_required')]"
            v-model="offset"
            v-if="asset_type !== 'generic'"
            class="rounded-0"
            outlined
            type="number"
        />

        <v-select
            :label="$t('asset_specification.screw_pitch_number')"
            v-if="asset_type === 'cuve'"
            :items="screw_numbers"
            v-model="index_screw_number"
            :rules="[l => (!!l || l >= 0) || $t('asset_specification.screw_pitch_number_required')]"
            item-text="value"
            item-value="index"
            class="rounded-0" outlined
        />

        <v-autocomplete
            :label="asset_type !== 'generic' ? $t('asset_specification.liquid_type') : $t('asset_specification.honey_type')"
            :items="liquid_types"
            :rules="[l => !!l || (asset_type !== 'generic' ? $t('asset_specification.liquid_type_required') : $t('asset_specification.honey_type_required'))]"
            v-model="id_liquid_type"
            :no-data-text="asset_type !== 'generic' ? $t('asset_specification.no_liquid_type') : $t('asset_specification.no_honey_type')"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="name"
            item-value="id_asset_liquid"
            class="rounded-0"
            outlined
        />

        <v-select
            :label="$t('asset_specification.localisation')"
            v-if="asset_type !== 'generic'"
            :menu-props="{ bottom: true, offsetY: true }"
            :items="localisation_types"
            :rules="[l => !!l || $t('asset_specification.localisation_required')]"
            v-model="id_localisation_type"
            item-text="value"
            item-value="id_referential"
            class="rounded-0"
            outlined
        />

        <v-select
            :label="$t('asset_specification.monitoring_type')"
            :items="monitoring_types"
            :rules="[l => !!l || $t('asset_specification.monitoring_type_required')]"
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="index_monitoring_type"
            v-if="asset_type !== 'generic'"
            item-text="value"
            item-value="index"
            class="rounded-0"
            outlined
        />
    </div>
</template>

<script>
export default {
    name: 'asset-specification',
    data() {
        return {
            offset: 0,
            liquid_types: [],
            id_liquid_type: null,
            loading_liquid_types: false,
            localisation_types: [],
            id_localisation_type: null,
            loading_localisation_types: false,
            screw_numbers: [
                {
                    index: 0,
                    value: this.$t('asset_specification.no_screw_pitch')
                },
                {
                    index: 1,
                    value: this.$t('asset_specification.small_screw_pitch')
                },
                {
                    index: 2,
                    value: this.$t('asset_specification.intermediate_screw_pitch')
                },
                {
                    index: 3,
                    value: this.$t('asset_specification.large_screw_pitch')
                },
            ],
            index_screw_number: 0,
            monitoring_types: [
                {
                    index: '-1',
                    value: this.$t('asset_specification.monitoring_consumption')
                },
                {
                    index: '1',
                    value: this.$t('asset_specification.monitoring_filling')
                }
            ],
            index_monitoring_type: null,
        };
    },
    props: {
        device_number: {
            type: String,
            required: true
        },
        id_professional: {
            type: Number,
            required: false
        },
        asset_type: {
            type: String,
            required: true
        }
    },
    watch: {
        id_professional: {
            handler() {
                this.getProfessionalLiquids();
            }
        }
    },
    methods: {
        getProfessionalLiquids() {
            this.loading_liquid_types = true;
            if(this.id_professional && this.asset_type) {
                return this.axios.get(
                    '/api/v1/professional/' + this.id_professional + '/asset_liquids/type/' + this.asset_type
                ).then((success) => {
                    this.liquid_types = success.data.data;
                }).catch((error) => {
                    this.manageError('error', this.$t('asset_specification.error_get_liquids'), error);
                }).finally(() => {
                    this.loading_liquid_types = false;
                });
            }
        },

        async getLocalisationTypes() {
            this.loading_localisation_types = true;

            return this.axios.get(
                `/api/v1/referential/localisation_types/${this.$i18n.locale.substr(0,2)}`
            ).then((success) => {
                this.localisation_types = success.data.data;
            }).catch((error) => {
                this.manageError('error', this.$t('asset_specification.error_get_localisations'), error);
            }).finally(() => {
                this.loading_localisation_types = false;
            });
        },

        fillDataFromParamsToStepsAssetSpecification() {
            const params = this.$store.getters['autofill/params'];
            this.offset = params.offset ?? 0;
            this.id_liquid_type = this.liquid_types.find(liquid => liquid.name === params.liquid_name)?.id_asset_liquid ?? null;
            this.id_localisation_type = this.localisation_types.find(localisation => localisation.value === params.localisation_type)?.id_referential ?? null;
            this.index_monitoring_type = params.monitoring_type ?? null;
        },
    },
    async mounted() {
        await this.getProfessionalLiquids();
        await this.getLocalisationTypes();
        this.fillDataFromParamsToStepsAssetSpecification();
    }
}
</script>
